import { BrandOcelotConfig } from '@/types/ocelotConfig';

const MotherhoodConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:4pukmqcfi1k',
    },
    placeholderImage: '/image/motherhood-logo.svg',
  },
  analytics: {
    brandName: 'motherhood',
    google: {
      measurement_id: 'G-R0SMEE4YGD',
      dimensions: {
        page_id: '1',
        mobile_website: '2',
      },
      viewId: 227422125,
      hostname: 'www.motherhood.se',
    },
    parsely: {
      siteId: 'motherhood.se',
    },
    sifo: {
      propertyId: 'a875b3918a5e48bab2ce568a7eb5d501',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_motherhood',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_motherhood.js',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'csMatchingKey', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    recommendedArticles: {
      enabled: false,
    },
    strossle: {
      enabled: true,
      id: 'f3e3966d-94c2-40d1-a4e2-80d60f62e1c8',
    },
    secondArticle: {
      enabled: true,
    },
    trendingMenu: {
      enabled: false,
    },
  },
  pages: {
    author: {
      enabled: false,
    },
    instagram: {
      enabled: false,
    },
    video: {
      enabled: false,
    },
  },
  seo: {
    schemaMarkup: {
      name: 'Motherhood',
      foundingDate: '2020-01-01',
      alternateName: ['Motherhood.se'],
      sameAs: ['https://www.facebook.com/Motherhood.sverige/', 'https://www.instagram.com/motherhood.se/'],
      webPages: ['/omoss', '/nyhetsbrev', '/omcookies', '/kontaktaoss', '/alla-amnen'],
    },
  },
  ads: {
    video: {
      clickToPlay: 'uES06Oub',
      autoPlay: 'ZUkc4M5g',
    },
  },
  cmp: {
    onetrust_id: 'c0c1e7bf-bc49-4298-a45f-df2d4794b1cc',
  },
};

export default MotherhoodConfig;
