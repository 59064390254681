import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const Button = tw.theme({
  extend: BaseNavigationTheme.Button,
  base: 'whitespace-nowrap',
});

const Link = tw.theme({
  variants: {
    colors: {
      bar: [
        'text-black',
        'border-transparent',
        'hover:border-cherry-blossom-300',
        'lg:hover:border-transparent',
        'lg:hover:text-cherry-blossom-300',
      ],
      drawer: ['text-black'],
    },
    size: {
      bar: ['p-2.5'],
      controls: ['p-0'],
      drawer: ['py-2.5'],
    },
    variant: {
      bar: ['text-headline-xs', 'inline-block', 'whitespace-nowrap', 'border-b-3', 'transition-colors'],
      drawerPrimary: ['text-headline-xs', 'font-bold', 'block'],
      drawerSecondary: ['text-headline-xs', 'block'],
    },
  },
});

export default Object.assign({}, { Button, Link });
