import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import MaBraLogo from '@/mabra/public/image/mabra-logo.svg';
import BlobHamburger from '@/motherhood/public/image/blob-hamburger.svg';
import BlobLogo from '@/motherhood/public/image/blob-logo.svg';
import MotherhoodLogo from '@/motherhood/public/image/motherhood-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneNavigation, StandaloneNavigationProps } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';

const logo: StandaloneNavigationProps['logo'] = (
  <>
    <BlobLogo className="absolute left-0 top-0 !h-20 !w-auto -translate-x-8 -translate-y-6" />
    <MotherhoodLogo className="relative" />
  </>
);

const menu: StandaloneNavigationProps['menu'] = (
  <>
    <Navigation.Link href="/gravid" content="Gravid" />
    <Navigation.Link href="/bebis" content="Bebis & Småbarn" />
    <Navigation.Link href="/skolbarn" content="Skolbarn" />
    <Navigation.Link href="/tonaringar" content="Tonåringar" />
    <Navigation.Link href="/mammaliv" content="Mammaliv" />
    <Navigation.Link href="/bloggar" content="Bloggar" />
  </>
);

const controls: StandaloneNavigationProps['controls'] = (
  <button type="button" onClick={showOneTrustConsent}>
    <Icon name="cookie" options={{ className: 'text-black', size: 'medium' }} />
  </button>
);

const getDrawer = () =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          { href: '/gravid', content: 'Gravid' },
          { href: '/bebis', content: 'Bebis & Småbarn' },
          { href: '/skolbarn', content: 'Skolbarn' },
          { href: '/tonaringar', content: 'Tonåringar' },
          { href: '/mammaliv', content: 'Mammaliv' },
          { href: '/bloggar', content: 'Bloggar' },
        ],
      },
      {
        links: [
          { href: '/omoss', content: 'Om oss' },
          { href: '/nyhetsbrev', content: 'Nyhetsbrev' },
          { href: 'https://ocast.com/se/motherhoodse/', content: 'Annonsera' },
          { href: '/omcookies', content: 'Om Cookies' },
          { href: '/kontaktaoss', content: 'Kontakta oss' },
          {
            href: '#',
            content: 'Hantera preferenser',
            options: {
              onClick: (e: MouseEvent) => {
                showOneTrustConsent();
                e.preventDefault();
              },
            },
          },
          { href: '/creative-studio', content: 'Creative studio' },
        ],
      },
    ],
    toggle: (
      <div className="relative z-1 mix-blend-multiply">
        <BlobHamburger className="absolute left-0 top-0 !h-20 !w-auto -translate-x-8 -translate-y-6" />
        <Navigation.Drawer.Toggle />
      </div>
    ),
  });

const soMe: StandaloneNavigationProps['soMe'] = (
  <Navigation.SoMe.Group>
    <Navigation.SoMe.Headline>Följ oss</Navigation.SoMe.Headline>
    <Navigation.SoMe
      href="https://www.facebook.com/motherhood.sverige/"
      name="facebook"
      aria-label="Länk till Facebook"
    />
    <Navigation.SoMe
      href="https://www.instagram.com/motherhood.se/"
      name="instagram"
      aria-label="Länk till Instagram"
    />
    <Button
      href="https://news.google.com/publications/CAAqBwgKMObVpAswqeC8Aw?hl=sv&gl=SE&ceid=SE%3Asv"
      target="_blank"
      options={{
        size: 'small',
        className: '!pt-0.5 !pb-0.75',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Navigation.SoMe.Group>
);

export const MotherhoodStandaloneNavigation: typeof StandaloneNavigation = () => {
  const drawer = getDrawer();

  return (
    <>
      <div className="flex items-center justify-end bg-gray-50 px-6 py-1 text-gray-600 text-headline-xs">
        En del av <MaBraLogo className="ml-2 h-4 text-black" />
      </div>
      <StandaloneNavigation {...{ logo, menu, controls, drawer, soMe }} />
    </>
  );
};
