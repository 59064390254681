import AdsManager from './AdsManager';
import { configureForDevelopment } from './placement/local';
import { enableGrowthbook, getGrowthbookGroupVideo } from './abtest/growthbook';
import adSettings from './config/adsettings';
import { getAdScheduleId } from './config/adschedule';
import ppid from './config/ppid';
import adConfig from './config/adslots';
import initConsent, { checkForUserConsent } from './consent/consent';
import initGpt from './googletag/googletag';
import log from './helpers/log';
import { collector } from './adstats/collector';
import waitFor from './helpers/waitfor';
import { initAdResources } from './resource/resource';
import { isVideoPage } from './config/domain';
import { stringIfAny } from './helpers/utils';
import { getModules } from './config/modules';
import { hasTcfConsent } from './consent/tcfv2';

let processedIds: string[] = [];
const processed = { divIds: [] as string[] };
const hasValidAdsUniqueId = (id: string) => {

  const defined = typeof id !== 'undefined' 
  const notProcessed = !processedIds.includes(id);
  return defined && notProcessed;
}
  
  

const isPlacementsLoaded = (uniqueId: string) => 
  window.ama ? window.ama?.domLoaded?.[uniqueId] : window?.loadedAdPlacement?.[uniqueId];
  

/**
 * Initializes the loading of ads on a page.
 * @param uniqueId The unique ID appended to placements div-ids.
 * @param options Custom settings, e.g., { dfpSection = "..." }.
 * @returns A Promise that resolves once the initialization is complete.
 */
const initializeAds = async (adsUniqueId: string | number = window.ama ? window.ama.adsUniqueId : window.adsUniqueId, options: InitOptions = {}): Promise<void> => {
  
  log('initializeAds()', adsUniqueId, options);

  const { useStats, useTADads, useGrowthbook, usePlacementCheck } = getModules();
  try {
    log('adsUniqueId, stringIfAny(adsUniqueId)', adsUniqueId, stringIfAny(adsUniqueId));
    let uniqueId = stringIfAny(adsUniqueId);
    let { videoOnly, dfpSection, isSecondArticle = false, serverLoadedConfig } = options;

    if (!videoOnly && !hasValidAdsUniqueId(uniqueId)) {
      processedIds.includes(uniqueId) && console.warn(`Ads:: already processed ${uniqueId}`);

      log(`Ads:: Valid uniqueId not set yet, waiting for updated ${window.ama ? 'window.ama.adsUniqueId' : 'window.adsUniqueId'} ...`);
      await waitFor(() => hasValidAdsUniqueId(stringIfAny(window.ama ? window.ama.adsUniqueId : window.adsUniqueId)), {
        timeout: 10000,
        errorMessage: `Timeout: Valid adsUniqueId is missing - ${uniqueId}`,
      });
      uniqueId = stringIfAny(window.ama ? window.ama.adsUniqueId : window.adsUniqueId);
    }

    !uniqueId && console.warn('Ads:: uniqueId is missing. it might be set to late');

    processedIds.push(uniqueId);

    adSettings.refresh();

    log('Get config for ', uniqueId, adSettings.hostName, adSettings.device, { ...adSettings });

    const pageNo = [...processedIds].length;

    let config: any = await adConfig.getModifiedConfig(adSettings.hostName, serverLoadedConfig);

///////////////////
//////////////////////////////////////////
/////////////

    config = adConfig.addExtraPlacements(config, 'intext', processed);
    config = adConfig.addExtraPlacements(config, 'responsive', processed);

    if ( usePlacementCheck && !videoOnly) {
      if (!isPlacementsLoaded(uniqueId)) {
        log(`Ads:: Wait for Placement Wrappers to be loaded for ${uniqueId} ...`);
        await waitFor(() => isPlacementsLoaded(uniqueId), {
          timeout: 10000,
          errorMessage: `Timeout: Placementwrappers are missing for ${uniqueId}`,
        });
      }
      log('Ads:: Placement Wrappers Loaded for ', uniqueId);
    }

    let filteredAds: any = adSettings.filterAds ? adConfig.filter(config, adSettings.isNativeAd) : config;

    log('filteredAds incl only no-processed', filteredAds);

    const tcData = await initConsent();

    initGpt();

    useStats && collector.start(config?.statscollector);

    useGrowthbook && await enableGrowthbook();
    const hasConsent = (useTADads && await checkForUserConsent()) || hasTcfConsent(tcData);
    if (useTADads && !hasConsent) {
      if (window.ama) {
        window.ama.adsUseDemandManager = 'false';
      } else {
        window.adsUseDemandManager = 'false';
      }
      dfpSection = 'optout';
    }
    AdsManager.init(filteredAds, ppid.get(), uniqueId, dfpSection, pageNo, hasConsent, videoOnly, isSecondArticle );
  } catch (error) {
    console.error('Error initializing ads:', error);
  }
};

// get attribute ads-conf from current script
const adsPlatformConfJson = document.getElementById('ads-manager')?.getAttribute('data-ads-conf') || undefined;

initAdResources(adsPlatformConfJson)
  .then((config) => {
    log('AdResources loaded');

    if(window.ama){
      window.ama.init = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {
        log('DFPInit - window.ama.init called initializeAds ...');
        initializeAds(uniqueId, options);
      };  
      log('window.ama.init available');
    }
    window.DFPInit = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {
      log('DFPInit - window.DFPInit called initializeAds ...');
      initializeAds(uniqueId, options);
    };
    log('window.DFPInit available');

    adSettings.init();
    const options: InitOptions = { videoOnly: isVideoPage()};
    if (config) {
      options.serverLoadedConfig = config;
    }

    adSettings.autoloadAds && initializeAds(window.ama ? window.ama.adsUniqueId : window.adsUniqueId , options);

    window.ads = {
      getAdScheduleId,
      getVideoExperimentGroup: getGrowthbookGroupVideo,
      getVideoPpId: ppid.get,
    };
    if (window.ama) {
      window.amav = {
        getAdScheduleId,
        getVideoExperimentGroup: getGrowthbookGroupVideo,
        getVideoPpId: ppid.get,
      };  
      log('window.ama.video available');
    }

    log('window.ads available');
  })
  .catch((error) => {
    console.error('AdResources fail to load', error);
  });
