import log from "../helpers/log";
import { getDevice } from "./device";
import { getSiteName } from "./domain";

export type LoadedByAdsLib = {
    demandManager?: boolean,
    config?: boolean,
};

export type AdsConfigJson = {
    siteName?: string;
    usePlacementCheck?: boolean;
    pageMeta?: {
        isNativeAd?: boolean;
        hideAds?: '0' | '1';
        device?: 'mobile' | 'desktop';
    };
    adsData?: {
        adsConfig?: IAdsConfig // response from https://se-ads-microservice.ads.allermedia.io/api/v1/config/get[site]
        adsUniqueId?: string;
    };
    preConfig?: {
        growthbooks?: GrowthbookKeyValue[];
        enableDemandManager?: 'true' | 'false';
    }; // response from https://se-ads-microservice.ads.allermedia.io/api/v1/config/preFetch?site_id=[site]
    topAdTarget?: string;   // div id for top ad placement
    loadedByAdsLib?: LoadedByAdsLib;
  
};
export const parseAdsConfig = (adsConfigJson: string | undefined) : AdsConfigJson => adsConfigJson ? JSON.parse(adsConfigJson) : {};

export const loadConfig = (adsConfigJson: AdsConfigJson) => {
    const {
        siteName,
        usePlacementCheck,
        pageMeta = {},
        adsData = {},
        preConfig = {},
        topAdTarget = '',
        loadedByAdsLib = {
            demandManager: true,
            config: true,
        },
    } = adsConfigJson;

    if (loadedByAdsLib?.config) {
        const displayAds = !(pageMeta?.isNativeAd || pageMeta?.hideAds === '1') ? 'true' : 'false';
        const isNativeAd = pageMeta?.isNativeAd || pageMeta?.hideAds === '1';
        const adsDevice = pageMeta?.device || getDevice();
        
        const adsUniqueId = typeof adsData.adsUniqueId !== 'undefined' ? `${adsData.adsUniqueId}` : undefined;
        const { adsConfig } = adsData;
        const dfpId = adsConfig?.dfpId;
        
        const growthbooks = preConfig.growthbooks || [];
        const defaultEnableDemandManager = true;
        const defaultAutoLoadAds = true;
        const defaultFilterAds = true;
        const adsUseDemandManager =
            typeof preConfig?.enableDemandManager === 'undefined'
            ? defaultEnableDemandManager
            : preConfig.enableDemandManager === 'true';
        const site = `${siteName ? siteName : getSiteName()}`;
        let useAMA = site === 'motherhood';        

        if(useAMA || window.ama) {
            log('useAMA  window.ama?.adsUniqueId: ', window.ama?.adsUniqueId);

            window.ama = { 
                siteName: site,
                usePlacementCheck: !!usePlacementCheck,        
                adsUniqueId: window.ama?.adsUniqueId || adsUniqueId,         
                displayAds: `${displayAds}`,
                adsUseDemandManager: `${adsUseDemandManager}`,
                autoloadAds: `${defaultAutoLoadAds}`,
                device: `${adsDevice}`,
                filterAds: `${defaultFilterAds}`,
                growthbooks: growthbooks,
                isNativeAd: `${isNativeAd}`, 
                ...window.ama,  
            }
            window.device = `${adsDevice}`; // Needed for demandManager

        } else {
            // Deprecated, will be removed when all platforms/users has window.ama
            window.siteName =  site;        
            window.adsUniqueId = window.adsUniqueId || adsUniqueId;            
            window.displayAds = `${displayAds}`;
            window.adsUseDemandManager = `${adsUseDemandManager}`;
            window.autoloadAds = `${defaultAutoLoadAds}`;
            window.device = `${adsDevice}`;
            window.filterAds = `${defaultFilterAds}`;
            window.growthbooks = growthbooks;
            window.isNativeAd = `${isNativeAd}`;
            window.usePlacementCheck = !!usePlacementCheck;
        }

        // ToDo: Move top-ad placement handling to platform (ocelot)  
        const isApp = window.location.search.includes('allerapp=1');
        let topAdElement = document.createElement('div');
        topAdElement.id = `${topAdTarget}`;
        topAdElement.setAttribute('ad-placement', 'top_ad');
        if (!isApp) {
            window.document.body.insertBefore(topAdElement, window.document.body.firstChild);
        }        
        log('Platform adsConfig is loaded');
        return adsConfig;            
    } else {
        log('Platform adsConfig not loaded by ads-lib');
    }
}

