import log from '../helpers/log';
import { capitalizeFirst, stripDashAndAfter, isEmpty } from '../helpers/utils';

const patternRegularSubdomain = /^(?:(?!www).)*$/m;

let amaSite: string = undefined;
let amacc: string = undefined;
let amaHost: string = undefined;

export const usePlacementCheck = (): boolean => window.ama ? window.ama.usePlacementCheck : window.usePlacementCheck;

export const isVideoPage = (): boolean => {
  const path = window.location.pathname;
  return path.includes('/video/');
};

const patternSubdomain = /^(\w+\.\w+\.\w+)$/m;
/**
 * Check if the current location.host is a regular (www) subdomain
 * or a blog
 * @return {boolean}
 */
export const hostIsSpecificSubdomain = (): boolean =>
  patternSubdomain.test(window.location.host) && patternRegularSubdomain.test(window.location.host);

const hasDfpId = (devSite: string): boolean => {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('dfp_id') && urlParams.get('dfp_id') === devSite;
  }
  return false;
};

const devSites = ['labdevs.com', 'localhost', 'ocelot-', 'www-dev','stage','test','approval','-'];
const dfpIdSite = 'adtech';

const isDevSite = devSites.some((site) => window?.location?.hostname?.includes(site)) || hasDfpId(dfpIdSite);

export const getHostName = (): string => {

  if(!isEmpty(amaHost)) return amaHost;

  log('getHostName calculation ...', amaSite);

  resolveHost();

  return amaHost;
};

export const getSiteName = (): string => {

  if(!isEmpty(amaSite)) return amaSite;

  // Set siteName to dfp_id "adtech" if set on querystring
  if (hasDfpId(dfpIdSite)) {
    log('getSiteName hasDfpId amaSite:', amaSite);
    amaSite = dfpIdSite;
    return amaSite;
  }

  resolveHost();

  if (!amaSite) {
    throw new Error(`Missing DEV_SITE or domain pattern in ${window.location.host}`);
  }
  log('getSiteName () amaSite :', amaSite, window.siteName, window.ama);

  return amaSite;
};

const hostExceptions: { [key: string]: string;} = {
  'mabra.se' : 'mabra.com',
  'residence.se' : 'residencemagazine.se'
};

export const getHostNameFromSite = (name: string): string => {
  
  if(isEmpty(name)) return '';    

  const hostName = `${name}.${resolveCountryCode()}`;
  const hn = hostExceptions[hostName] ?? hostName;

  log(`getHostNameFromSite() name: ${name} : hostname: ${hn}`);

  return hn;
}

export const isSubDomain = (hostname: string): boolean => {
  const hostParts = typeof hostname === 'string' ? hostname.split('.') : [];
  return hostParts.length > 2;
};

export const resolveDevSiteName = (dfpId: string): string => {
  const siteName = isSubDomain(dfpId) ? dfpId : `${process.env.DEV_SITE}`;
  if (isEmpty(siteName)) {
    throw new Error(`Missing DEV_SITE`);
  }
  return siteName;
};

const resolveHost = () => {

  const parts = window.location.host.split('.');
  const sub = parts.length > 2 ? parts[parts.length - 3] : undefined;
  const domain = parts.length > 1 ? parts[parts.length - 2] : undefined;
  const top = parts.length > 0 ? parts[parts.length - 1] : undefined;

  const domainTld = domain ? `${domain}.${top}` : undefined;
  const subDomainTld = sub ? `${sub}.${domainTld}` : undefined;

  amaHost = !sub.includes('www') && subDomainTld || domainTld || top;
  amaSite = !sub.includes('www') && sub || domain;


  if (isDevSite) {

    if ( !(sub && !(sub.includes('-') || sub.includes('www')))) {
      amaHost = domainTld || top;
      amaSite = domain;  
    }
    // ToDo Add se dev handling
    // if (!isEmpty(window.ama ? window.ama.siteName: window.siteName)) {
    //   amaSite = window.ama ? window.ama.siteName : window.siteName;
    // }
    // amaHost = getHostNameFromSite(getDevSite());
  }

  log('resolveHost ', parts, amaSite, amaHost);
}


export const getHostnameFromLocation = (): string => {

  if (typeof amaHost !== 'undefined') return amaHost;

  resolveHost();

  log('getHostnameFromLocation calculation ... ', amaHost);

  return amaHost;
};

export const getDomainFromHostName = (hostname: string): string => {
  let domainTld = '';
  if (typeof hostname === 'string') {
    const [, domain, topLevelDomain] = hostname.split('.');
    domainTld = domain && topLevelDomain ? `${domain}.${topLevelDomain}` : hostname;
  }
  log('getDomainFromHostName ', domainTld);

  return domainTld;
};

export const getSubdomainAdwatchName = (): string => {
  const components = window.location.host.split('.');
  if (components.length !== 3) {
    throw Error(`Unexpected length of location.host`);
  }
  const [subdomain, domain] = components;
  const capitalizedDomain = capitalizeFirst(domain);
  return `${subdomain}${capitalizedDomain}`;
};
export const alterSubdomain = (hostname: string): string => {
  let host = hostname;

  if (typeof host === 'string') {
    // Modify main sites
    host = host.replace(/((^www-live.)|(^www-dev.)|(^golive.)|(^www.))+/, '');

    let [subDomain, domain, topLevelDomain] = host.split('.');

    if (domain && topLevelDomain) {
      if (window.ama ? window.ama.usePlacementCheck : window.usePlacementCheck) {
        host = `${domain}.${topLevelDomain}`;
      } else if (subDomain && host.split('.').length > 2) {
        // Modify blogs: Remove -stage, -test, -approval etc from subdomain
        subDomain = stripDashAndAfter(subDomain);
        host = `${subDomain}.${domain}.${topLevelDomain}`;
      }
    }
  }
  return host;
};

export const setDfpSection = (section: string): string => {
  let dfpSection = 'general';

  if (section) {
    dfpSection = section;
  } else {
    if (location.pathname.split('/')[1] !== '' && location.hostname.split('.')[0] == 'www') {
      dfpSection = location.pathname.split('/')[1];
    } else if (location.hostname.split('.')[0] !== 'www') {
      dfpSection = getHostnameFromLocation();
    }
  }
  return dfpSection;
};

export const setDfpId = (configDfpId: string): string => {
  let dfpId = configDfpId;
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has('dfp_id')) {
    dfpId = urlParams.get('dfp_id') || configDfpId;
  }
  return dfpId;
};

export const resolveAdUnitPathDfpId = (dfpId: string) => {
  let resolveId = dfpId;
  const isSub = isSubDomain(dfpId);

  if (dfpId === 'global' || isSub) {
    let hostname;
///////////////////

///////////////////////////////////////////////
/////////////////////////////////////////////

////////////

    hostname = window.location.host;

/////////////

    resolveId = getDomainFromHostName(hostname);
  }
  return resolveId;
};

export const resolveCountryCode = (): string => {

  if (typeof amacc !== 'undefined') return amacc;

  const hostName = getHostnameFromLocation();
  const exceptions: { [key: string]: string;} = {'mabra.com' : 'mabra.se'};

  amacc = (exceptions[hostName] ?? hostName).split('.').pop();   

  log('resolveCountryCode calculated ... ', amacc);

  return amacc;
}
