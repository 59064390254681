import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import MotherhoodLogo from '@/motherhood/public/image/motherhood-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';

const currentYear = new Date().getFullYear();

const logo: StandaloneFooterProps['logo'] = <MotherhoodLogo />;

const description: StandaloneFooterProps['description'] = (
  <>
    Motherhood – för alla mammor. Oavsett om du har fött egna, andras eller fostrar framtidens changemakers. Vi tror på
    kraften i systerskapet, på mammor som stöttar varandra, och som vill dela sina erfarenheter och kunskap.
  </>
);

const editors: StandaloneFooterProps['editors'] = 'Ansvarig utgivare och chefredaktör: Jennie Sandberg';

const copyright: StandaloneFooterProps['copyright'] = (
  <span>
    För prenumerationsärenden, ring{' '}
    <a className="underline" href="tel:0424443000">
      042 444 30 00
    </a>{' '}
    • Ansvarig utgivare: Jennie Sandberg © Copyright {currentYear} motherhood.se
    <br />
    Motherhood är en del av{' '}
    <a target="_blank" className="underline" href="https://www.aller.se">
      Aller Media
    </a>
    . Humlegårdsgatan 6, 114 46 Stockholm,{' '}
    <a className="underline" href="tel:086794600">
      08 679 46 00
    </a>
  </span>
);

const columns: StandaloneFooterProps['columns'] = (
  <>
    <Footer.Column>
      <Footer.List.Title>Om oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/omoss" content="Om oss" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/nyhetsbrev" content="Nyhetsbrev" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/alla-amnen" content="Alla ämnen" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/omcookies" content="Om cookies" />
        </Footer.List.Item>
        <Footer.List.Item>
          <button type="button" onClick={showOneTrustConsent}>
            Hantera preferenser
          </button>
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://www.aller.se/integritetspolicy/" target="_blank" content="Integritetspolicy" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://www.aller.se/ai-policy/" target="_blank" content="Aller Medias AI-policy" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/creative-studio/riktlinjer-for-kommersiellt-innehall-pa-motherhoodse/10293589"
            content="Kommersiell policy"
          />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Motherhood.se</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/gravid" content="Gravid" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/bebis" content="Bebis & Småbarn" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/skolbarn" content="Skolbarn" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/tonaringar" content="Tonåringar" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/mammaliv" content="Mammaliv" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/bloggar" content="Bloggar" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Kontakta oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/kontaktaoss" content="Kontakta oss här" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>
  </>
);

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>

    <Footer.SoMe href="https://www.facebook.com/Motherhood.sverige/" name="facebook" aria-label="Länk till Facebook" />
    <Footer.SoMe href="https://www.instagram.com/motherhood.se/" name="instagram" aria-label="Länk till Instagram" />
    <Button
      href="https://news.google.com/publications/CAAqBwgKMObVpAswqeC8Aw?hl=sv&gl=SE&ceid=SE%3Asv"
      target="_blank"
      options={{
        size: 'small',
        className: '!pt-0.5 !pb-0.75',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Footer.SoMe.Group>
);

export const MotherhoodStandaloneFooter: typeof StandaloneFooter = () => {
  return <StandaloneFooter {...{ columns, copyright, description, editors, logo, soMe }} />;
};
