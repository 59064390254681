import { resolveCountryCode } from './domain';

type CountryCode = 'se' | 'fi' | 'dk' | 'no';

interface CountryOptions extends Partial<Record<CountryCode, Modules>> {}

const PREBID_SCRIPT = {
  'se': 'https://micro.rubiconproject.com/prebid/dynamic/13012.js',
  'fi': 'https://micro.rubiconproject.com/prebid/dynamic/13046.js', 
  'dk': 'https://micro.rubiconproject.com/prebid/dynamic/15518.js',
  'no': '',
}

let countryModules: CountryOptions = {};

export const getModules = (): Modules => {

  const countryCode = resolveCountryCode() as CountryCode;

  if (countryModules[countryCode]) return countryModules[countryCode];

  let modules: Modules = {};

  modules.prebidScript = PREBID_SCRIPT[countryCode];

  switch (countryCode) {
    case 'se':
      modules = {
        ...modules,
        useStats: true,
        useTADads: true,
        useGrowthbook: true,
        useSeenthis: true,
      };
      break;
    case 'no':
      break;
    case 'dk':
      break;
    case 'fi':
      break;
  }

  countryModules[countryCode] = modules;  
  return modules;
}