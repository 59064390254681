/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "accordion": {
    "extend": {
      "toggle_variant_default": "sm:hidden",
      "content_variant_default": "sm:block"
    }
  },
  "bar_group_colors_default": "bg-white lg:bg-transparent",
  "bar_group_size_default": "col-span-3 -mx-4 lg:ml-8 lg:mr-0 mt-3 lg:mt-0 lg:group-data-[open]:max-w-screen-sm",
  "bar_group_variant_default": "relative overflow-x-auto overflow-y-hidden row-start-2 lg:row-auto lg:col-auto",
  "bar_colors_default": "bg-sand-50 group-data-[open]:bg-white wings-sand-50 group-data-[open]:wings-white",
  "bar_size_default": "px-4 pt-3 lg:py-3 gap-x-8",
  "bar_variant_default": "grid grid-cols-[auto_1fr_auto] lg:grid-cols-[auto_auto_1fr_auto] items-center relative wings",
  "drawer_toggle_animation_default": "",
  "drawer_toggle_animation_alternative": "",
  "drawer_toggle_colors_default": "",
  "drawer_toggle_size_default": "w-7.5 h-7.5",
  "drawer_toggle_variant_default": "",
  "drawer_colors_default": "bg-white wings-white",
  "drawer_size_default": "px-3 sm:px-8 md:px-16 lg:px-25 py-5 sm:py-8 md:py-10 lg:py-13 w-full h-[calc(100dvh_-_100%)] gap-y-5",
  "drawer_variant_default": "absolute wings top-full left-0 scale-y-0 group-data-[open]:scale-y-100 -z-2 transition-transform origin-top overflow-y-auto lg:overflow-visible flex flex-col",
  "logo_colors_default": "text-white mix-blend-multiply",
  "logo_colors_alternative": "",
  "logo_size_default": "h-11 mx-1 sm:mx-2 flex-shrink-0 [&_svg]:h-9.5",
  "logo_size_alternative": "",
  "logo_variant_default": "relative z-1 transition-all",
  "menu_group_colors_default": "",
  "menu_group_size_default": "sm:gap-2 md:gap-3 lg:gap-4",
  "menu_group_variant_default": "h-full sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:overflow-y-auto",
  "menu_colors_primary": "",
  "menu_colors_secondary": "",
  "menu_colors_none": "",
  "menu_size_bar": "h-12 gap-x-3 px-4 lg:px-0",
  "menu_size_controls": "gap-x-3",
  "menu_size_drawer": "gap-0",
  "menu_variant_bar": "flex group-data-[open]:hidden flex-row items-center overflow-x-auto overflow-y-hidden",
  "menu_variant_controls": "flex flex-row",
  "menu_variant_drawer": "flex flex-col",
  "search_toggle_colors_default": "",
  "search_toggle_size_default": "",
  "search_toggle_variant_default": "",
  "search_colors_default": "bg-transparent",
  "search_size_bar": "w-full h-12 gap-x-2",
  "search_size_drawer": "w-full h-12",
  "search_variant_bar": "hidden lg:group-data-[open]:flex flex-row items-center",
  "search_variant_drawer": "lg:hidden [&_button]:hidden sm:[&_button]:block flex flex-row items-center max-w-screen-sm mx-auto",
  "soMe_group_colors_default": "",
  "soMe_group_size_default": "gap-3",
  "soMe_group_variant_default": "md:col-start-3 lg:col-start-4 self-start flex flex-wrap mt-3 pb-3",
  "soMe_headline_colors_default": "",
  "soMe_headline_size_default": "",
  "soMe_headline_variant_default": "w-full text-headline-2xs",
  "soMe_colors_default": "",
  "soMe_size_default": "",
  "soMe_variant_default": "self-end flex flex-wrap gap-3",
  "textField_input_colors_default": "",
  "textField_input_size_default": "!w-full",
  "textField_input_size_large": "!w-full",
  "textField_input_variant_default": "",
  "textField_colors_default": "",
  "textField_size_default": "",
  "textField_variant_default": "flex-grow",
  "colors_default": "",
  "size_default": "",
  "variant_default": "sticky top-0 left-0 z-menu max-w-screen-xl mx-auto group",
  "drawer_toggle_segment_animation_default": "first-of-type:-translate-y-2.5 third-of-type:hidden last-of-type:translate-y-2.5 peer-checked:translate-y-0 peer-checked:first-of-type:rotate-45 peer-checked:last-of-type:-rotate-45 peer-checked:second-of-type:scale-x-0",
  "drawer_toggle_segment_animation_alternative": "",
  "drawer_toggle_segment_colors_default": "!bg-black",
  "drawer_toggle_segment_size_default": "h-1 first-of-type:w-1/2 peer-checked:first-of-type:w-full",
  "drawer_toggle_segment_variant_default": "",
  "textField_input_status_default": "",
  "textField_input_status_error": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Navigation");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;