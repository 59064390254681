import waitFor from '../helpers/waitfor';
import log from '../helpers/log';
import loadScript from './loadScript';

import { loadConfig, parseAdsConfig, LoadedByAdsLib } from '../config/platform';
import { getModules } from '../config/modules';


let isTcfapiLoaded = false;
let isDemandManagerLoaded = false;

export const resourceTimeout = 20000;

export const isAdResourcesLoaded = () => isTcfapiLoaded && isDemandManagerLoaded;

const loadDemandManager = async (loadedByAdsLib: LoadedByAdsLib) => {
  if (loadedByAdsLib?.demandManager) {

    const { prebidScript } = getModules();

    try {
      if(window.ama ? window.ama.adsUseDemandManager === 'true' : window.adsUseDemandManager === 'true') {
        loadScript(prebidScript, { 
          async: true,
          defer: false,
          strategy: "beforeInteractive",
          referrerpolicy: "strict-origin-when-cross-origin"
        }).then( () => {
          isDemandManagerLoaded = true;
          log('DemandManagerLoaded');
        });  
      } else {
        log('DemandManagerLoaded DONE - not used');
        isDemandManagerLoaded = true;
      }
    } catch (error) {
      console.error('DemandManager failed to Load', error);
      isDemandManagerLoaded = true;
    }  
  } else {
    log('DemandManager not loaded by ads-lib');
    isDemandManagerLoaded = true;
  }
};

export const initAdResources = async (adsConfig: string | undefined) => {

  const parsedConfig = parseAdsConfig(adsConfig);

  // Load platformConfig if available
  const config = loadConfig(parsedConfig);

  loadDemandManager(parsedConfig.loadedByAdsLib);

  // Load tcfapi
  waitFor(() => typeof window?.__tcfapi !== 'undefined', { timeout: resourceTimeout, errorMessage: `Timeout: window.__tcfapi not available ` }).then(() => {
    isTcfapiLoaded = true;
    log('tcfApi is loaded');
  });

  log('waitFor isAdResourcesLoaded ...');

  await waitFor(isAdResourcesLoaded, { timeout: resourceTimeout, errorMessage: `Timeout: isAdResourcesLoaded failed ` });

  return config;
};
